<template>
  <div>
    <b-row>
      <b-col>
        <router-link to="/business-it-support/services">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Services
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link to="/business-it-support/issues">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Issues
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link to="/business-it-support/time-price">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Time &amp; Price
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {

}
</script>
